import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { JobTemplate } from "../../datamodel/job-template";
import { db } from "../../db";
import { OnlineTemplateListDto } from "../../entities/online-templates/online-template-list.dto";
import { OnlineTemplateMetaDto } from "../../entities/online-templates/online-template-meta.dto";

/**
 * JobService stores and loads jobs templates.
 */
@Injectable({
    providedIn: "root"
})
export class JobTemplateService {
    constructor(
        private readonly http: HttpClient) {
    }


    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public async listJobTemplates(limit: number = 101): Promise<Array<JobTemplate>> {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return db.transaction("r", db.jobTemplates, async () => await db.jobTemplates.orderBy("id").reverse().limit(limit).toArray());
    }

    public async save(jobTemplate: JobTemplate, newCorrelationId: boolean = false): Promise<number> {
        if (newCorrelationId) {
            jobTemplate.correlationId = CryptoHelper.getUUID();
        }

        return db.transaction("rw", db.jobTemplates, async () => {
            let jobId: number;
            if (jobTemplate.id) {
                await db.jobTemplates.update(jobTemplate.id, jobTemplate);
                jobId = jobTemplate.id;
            } else {
                jobId = await db.jobTemplates.add(jobTemplate);
            }
            console.info(`Template ${jobTemplate.id} (${jobTemplate.correlationId}) saved.`);
            return jobId;
        });
    }

    public async load(id: number): Promise<JobTemplate|undefined> {
        return db.transaction("r", db.jobTemplates,
            async () => await db.jobTemplates.where({ "id": id }).first());
    }

    public async loadByCorrelationId(correlationId: string): Promise<JobTemplate|undefined> {
        return db.transaction("r", db.jobTemplates,
            async () => await db.jobTemplates.where({ "correlationId": correlationId }).first());
    }

    public async delete(id: number): Promise<void> {
        await db.transaction("rw", db.jobTemplates,
            async () => await db.jobTemplates.where({ "id": id }).delete());
    }

    public async fetchOnlineTemplates(): Promise<Array<OnlineTemplateMetaDto>> {
        try {
            const response: OnlineTemplateListDto = await lastValueFrom(this.http.get<OnlineTemplateListDto>(`${environment.resourcesBaseUrl}/v1/templates/?ts=${Date.now()}`));
            return response.items;
        } catch (error) {
            console.warn("Failed to fetch online templates", error);
            return [];
        }
    }

    public async downloadOnlineTemplates(filename: string): Promise<Blob|undefined> {
        try {
            return await lastValueFrom(this.http.get(`${environment.resourcesBaseUrl}/v1/templates/files/${filename}?ts=${Date.now()}`, {
                responseType: "blob"
            }));
        } catch (error: any) {
            error.message = `Failed to download template ${filename}. ${error.message ?? ""}`;
            console.error(error);
            throw error;
        }
    }
}
