<mat-action-list *ngIf="propertyItems.length > 0" class="list">
    <button (click)="editProperty(property)" *ngFor="let property of propertyItems; let index = index" [disabled]="isReadonly" class="item" mat-list-item>
        <div class="buttonContent">
            <div *ngIf="property.nameKey" class="name" [class.highlight]="index < highlightFirstProperties">
                {{property.nameKey | translate}}:&nbsp;
            </div>
            <div *ngIf="property.value" class="value">
                {{property.value}}
            </div>
        </div>
    </button>
</mat-action-list>

<div *ngIf="!isReadonly && allowNewProperties" class="addPropertyButton">
    <button (click)="editProperty()" color="primary" mat-flat-button>
        {{'PropertyEditor.newProperty' | translate}}
    </button>
</div>
