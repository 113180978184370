<ion-content [fullscreen]="true" class="componentRoot">
    <ion-header>
        <ion-toolbar>
            <ion-title size="large">{{'JobTemplateList.browseTemplatesTitle'| translate}}</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="refreshOnlineTemplates()"><fa-icon [icon]="appIcons.genericRefresh"></fa-icon></ion-button>
                <ion-button (click)="close()"><fa-icon [icon]="appIcons.dialogClose"></fa-icon></ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <input #fileSelector accept=".qrt,.json" class="notRendered" type="file" />

    <mat-action-list>
        <button mat-list-item (click)="importFromFile()">
            <fa-icon [icon]="appIcons.fileIn" matListItemIcon></fa-icon>
            <span matListItemTitle>{{'JobTemplateList.importTemplateFromFile'|translate}}</span>
            <span matListItemMeta><fa-icon [icon]="appIcons.clickableListItemIcon"></fa-icon></span>
        </button>
    </mat-action-list>
    <mat-action-list>
        <button mat-list-item *ngIf="loading" disabled>
            <fa-icon [icon]="appIcons.loading" [animation]="'spin'" matListItemIcon></fa-icon>
            <span matListItemTitle>{{'Generic.loadingDots'|translate}}</span>
        </button>
        <button mat-list-item *ngIf="!loading && templates.length <= 0" disabled>
            <fa-icon [icon]="appIcons.checkFailed" matListItemIcon></fa-icon>
            <span matListItemTitle class="allow-line-feed">{{'JobTemplateList.noTemplatesCheckInternet'|translate}}</span>
        </button>

        <button (click)="import(template)" *ngFor="let template of templates" mat-list-item>
            <fa-icon [icon]="appIcons.jobTemplateEditorIcon" matListItemIcon *ngIf="!template.status"></fa-icon>
            <fa-icon [icon]="appIcons.loading" matListItemIcon [animation]="'spin'" *ngIf="template.status == 'loading'"></fa-icon>
            <fa-icon [icon]="appIcons.checkFailed" matListItemIcon *ngIf="template.status == 'error'"></fa-icon>
            <fa-icon [icon]="appIcons.checkSuccess" matListItemIcon *ngIf="template.status == 'imported'"></fa-icon>
            <span matListItemTitle>{{template.title}}</span>
            <span matListItemLine *ngIf="template.description" class="allow-line-feed">{{template.description}}</span>
            <span matListItemLine *ngIf="template.error" class="allow-line-feed">{{template.error}}</span>
            <!-- <span matListItemLine *ngIf="template.tags?.length"><span *ngFor="let tag of template.tags!">{{tag}}</span></span> -->
            <span matListItemMeta><fa-icon [icon]="appIcons.clickableListItemIcon"></fa-icon></span>
        </button>
    </mat-action-list>
</ion-content>
