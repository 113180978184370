import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { PartEditorModes } from "../../../business/components/part-editor/part-editor-modes";
import { MeasurementPoint } from "../../../business/datamodel/measurement-point";
import { BaseComponent } from "../base/base-component";

/**
 * MarkerAnnotationComponent is used to display markers on images where specific measurements should be taken.
 */
@Component({
    selector: "base-marker-annotation",
    templateUrl: "./marker-annotation.component.html",
    styleUrls: ["./marker-annotation.component.scss"]
})
export class MarkerAnnotationComponent extends BaseComponent {
    @Input()
    public image?: string;

    @Input()
    public mode: PartEditorModes = "edit";

    @Input()
    public markers: Array<MeasurementPoint> = [];

    @Input()
    public selectedMarker?: MeasurementPoint;

    @Input()
    public removeStyles: boolean = false;

    @Output()
    public selectedMarkerChange: EventEmitter<MeasurementPoint> = new EventEmitter<MeasurementPoint>();

    @Output()
    public changeDetected: EventEmitter<void> = new EventEmitter<void>();

    public currentlyMovingElementIsNewMarker: boolean = false;
    protected movableMarker: MeasurementPoint = new MeasurementPoint();
    protected aspectRatio: number = 0;
    @ViewChild("movableMarkerElement")
    private movableMarkerElementRef?: ElementRef<HTMLDivElement>;
    @ViewChild("canvas")
    private canvasElementRef?: ElementRef<HTMLDivElement>;
    @ViewChild("imageElement")
    private imageElementRef?: ElementRef<HTMLImageElement>;
    private readonly percentageMultiplier: number = 100;
    private currentlyMovingElement?: HTMLDivElement;
    private currentlyMovingMarker?: MeasurementPoint;
    private currentMouseCorrection?: { x: number; y: number };

    protected get allowEdit(): boolean {
        return this.mode == "edit";
    }

    protected componentInit(): void {
        // Do nothing for now
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public addMarker(): void {
        this.currentlyMovingElementIsNewMarker = true;
        this.currentlyMovingElement = this.movableMarkerElementRef?.nativeElement;
        this.currentlyMovingMarker = this.movableMarker;
    }

    public cancelAddMarker(): void {
        this.currentlyMovingElementIsNewMarker = false;
        this.currentlyMovingElement = undefined;
        this.currentlyMovingMarker = undefined;
        this.movableMarkerElementRef?.nativeElement.classList.remove("show");
    }

    public selectMarker(marker: MeasurementPoint): void {
        if (this.mode != "markerSelection") {
            return;
        }

        this.selectedMarkerChange.emit(marker);
    }

    protected mouseMoved(event: MouseEvent): void {
        if (this.currentlyMovingElement) {
            if (this.currentlyMovingElementIsNewMarker) {
                this.movableMarkerElementRef?.nativeElement.classList.add("show");
            }

            this.move(event.x, event.y);
        }
    }

    protected touchMove(event: TouchEvent): void {
        if (this.currentlyMovingElement && !this.currentlyMovingElementIsNewMarker) {
            // event.preventDefault();
            event.stopPropagation();

            const touch: Touch = event.touches[0];
            this.move(touch.pageX, touch.pageY);
        }
    }

    protected mouseDown(event: MouseEvent): void {
        this.canvasClick(event.x, event.y);
    }

    protected touchStart(event: TouchEvent): void {
        if (this.currentlyMovingElement && this.currentlyMovingElementIsNewMarker) {
            const touch: Touch = event.touches[0];
            this.canvasClick(touch.pageX, touch.pageY);
        }
    }

    protected mouseUp(): void {
        if (this.currentlyMovingElement && !this.currentlyMovingElementIsNewMarker) {
            this.currentlyMovingElement = undefined;
            this.currentMouseCorrection = undefined;
            this.movableMarkerElementRef?.nativeElement.classList.remove("show");
        }
    }

    protected startMovingMarker(marker: MeasurementPoint, event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (!this.allowEdit) {
            this.selectMarker(marker);
            return;
        }

        this.currentlyMovingElementIsNewMarker = false;
        this.currentlyMovingMarker = marker;
        this.currentlyMovingElement = event.target as HTMLDivElement;
    }

    protected calculateAspectRatio(): void {
        if (!this.imageElementRef?.nativeElement) {
            return;
        }

        this.aspectRatio = this.imageElementRef.nativeElement.naturalWidth / this.imageElementRef.nativeElement.naturalHeight;
    }

    private canvasClick(x: number, y: number): void {
        if (this.currentlyMovingElement && this.currentlyMovingElementIsNewMarker) {
            const position: { x: number; y: number } = this.calculateRelativePositionToCanvas(x, y);
            this.markers.push(new MeasurementPoint(this.markers.length + 1, position.x, position.y));

            this.currentlyMovingElement = undefined;
            this.movableMarkerElementRef?.nativeElement.classList.remove("show");
            this.currentMouseCorrection = undefined;
            this.currentlyMovingElementIsNewMarker = false;
        }
    }

    private calculateRelativePositionToCanvas(x: number, y: number): { x: number; y: number } {
        if (!this.canvasElementRef?.nativeElement) {
            return { x: 0, y: 0 };
        }

        const canvasBoundingRect: DOMRect = this.canvasElementRef.nativeElement.getBoundingClientRect();
        const movingMarkerBoundingBox: DOMRect|undefined = this.currentlyMovingElement?.getBoundingClientRect();

        if (!this.currentlyMovingElementIsNewMarker && !this.currentMouseCorrection && movingMarkerBoundingBox) {
            this.currentMouseCorrection = {
                /* eslint-disable @typescript-eslint/no-magic-numbers */
                x: movingMarkerBoundingBox.x - x + (movingMarkerBoundingBox.width / 2),
                y: movingMarkerBoundingBox.y - y + (movingMarkerBoundingBox.height / 2)
                /* eslint-enable @typescript-eslint/no-magic-numbers */
            };
        } else if (!this.currentMouseCorrection) {
            this.currentMouseCorrection = { x: 0, y: 0 };
        }

        const xPosition: number = x - canvasBoundingRect.x + this.currentMouseCorrection.x;
        const yPosition: number = y - canvasBoundingRect.y + this.currentMouseCorrection.y;

        return {
            x: (xPosition / canvasBoundingRect.width) * this.percentageMultiplier,
            y: (yPosition / canvasBoundingRect.height) * this.percentageMultiplier
        };
    }

    private move(mouseX: number, mouseY: number): void {
        if (!this.currentlyMovingElement || !this.canvasElementRef?.nativeElement || !this.currentlyMovingMarker) {
            return;
        }

        const position: { x: number; y: number } = this.calculateRelativePositionToCanvas(mouseX, mouseY);

        this.currentlyMovingMarker.x = position.x;
        this.currentlyMovingMarker.y = position.y;

        this.changeDetected.emit();
    }
}
