import { CryptoHelper } from "../../base/helper/crypto-helper";
import { Chart } from "./chart";
import { MeasuredValue } from "./measured-value";
import { SortOrders } from "./sort-orders";

/**
 * A Measurement contains all measured values of a single measuring event of a device. A device may produce multiple values in one measurement.
 */
export class Measurement {
    public id: string = CryptoHelper.getUUID();
    public localId: number = 0;
    public sourceId?: string;

    public iteration: number = 1;

    public name?: string;

    public device?: string;
    public deviceId?: string;
    public serialNumber?: string;
    public timestamp?: string;
    public comment?: string;

    public values: Array<MeasuredValue> = [];
    public charts: Array<Chart> = [];

    /**
     * Indicates if the value of the measurement is a measured value or metadata like device, deviceId, ...
     * @param measurement - The measurement which should contain the value in its measured values.
     * @param valueName - The name to search in the measured values.
     * @returns True if and only if the name was found in the measured values.
     */
    public static isMeasuredValue(measurement: Measurement, valueName: string): boolean {
        for (const measuredValue of measurement.values) {
            if (measuredValue.name == valueName) {
                return true;
            }
        }
        return false;
    }

    /**
     * Sorts the values in the data structure in logical order.
     */
    public sortValues(): void {
        this.values.sort((a: MeasuredValue, b: MeasuredValue) => {
            const nameA: string = (a.name || "").replace("Measurement.", "").toUpperCase();
            const nameB: string = (b.name || "").replace("Measurement.", "").toUpperCase();
            const indexA: number = SortOrders.metricsSortOrder.indexOf(nameA);
            const indexB: number = SortOrders.metricsSortOrder.indexOf(nameB);

            // If both names are in the sortOrder array, compare their indices
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            }

            // If one name is in the sortOrder array but the other is not, prioritize the one that is in the array
            if (indexA !== -1) { return -1; }
            if (indexB !== -1) { return 1; }

            // If neither name is in the sortOrder array, use a localeCompare as a fallback for alphabetical sorting
            return nameA.localeCompare(nameB);
        });
    }
}
