import { Injectable } from "@angular/core";

import { Measurement } from "../../datamodel/measurement";
import { db } from "../../db";
import { MeasurementV1 } from "../../pages/job-designer/sharing/v1/measurement.v1";

/**
 * Service to handle the quick measurements.
 */
@Injectable({
    providedIn: "root"
})
export class QuickMeasurementsService {
    public async listMeasurements(): Promise<Array<Measurement>> {
        const items: Array<MeasurementV1> = await db.transaction("r", db.quickMeasurements, async () => await db.quickMeasurements.orderBy("localId").reverse().toArray());
        const result: Array<Measurement> = [];
        for (const item of items) {
            result.push(MeasurementV1.toEntity(item));
        }
        return result;
    }

    public async add(measurement: Measurement): Promise<void> {
        const measurementDto: MeasurementV1 = MeasurementV1.fromEntity(measurement);

        if (measurement.iteration > 1 && measurement.id) {
            await this.delete(measurement.id);
        }

        return db.transaction("rw", db.quickMeasurements, async () => {
            await db.quickMeasurements.add(measurementDto);
        });
    }

    public async delete(id: string): Promise<void> {
        await db.transaction("rw", db.quickMeasurements,
            async () => await db.quickMeasurements.where({ "id": id }).delete());
    }

    public async deleteAll(): Promise<void> {
        await db.transaction("rw", db.quickMeasurements,
            async () => await db.quickMeasurements.clear());
    }
}
