import { Component, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { BaseComponent } from "../../../base/components/base/base-component";
import { ComponentCanDeactivate } from "../../../base/guards/navigate-away.guard";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { PartEditorComponent } from "../../components/part-editor/part-editor.component";
import { Part } from "../../datamodel/part";
import { DialogService } from "../../services/dialog/dialog.service";
import { ImageService } from "../../services/image/image-service";
import { PartService } from "../../services/part/part-service";

/**
 * Used to create or edit Parts.
 */
@Component({
    selector: "app-part-designer",
    templateUrl: "./part-designer.component.html",
    styleUrls: ["./part-designer.component.scss"]
})
export class PartDesignerComponent extends BaseComponent implements ComponentCanDeactivate {
    constructor(
        private route: ActivatedRoute,
        private partService: PartService,
        private navigationService: NavigationService,
        private snackbar: MatSnackBar,
        private translateService: TranslateService,
        private imageService: ImageService,
        private dialogService: DialogService) {
        super();
        this.caption = "PartDesigner.title";
    }

    @ViewChild("partEditorComponent")
    private partEditorComponent?: PartEditorComponent;
    public part: Part = new Part();
    private parameterSubscriber?: Subscription;

    public saving: boolean = false;

    protected componentInit(): void {
        this.route.params.subscribe(async (params: Params) => {
            let partId: number|undefined;
            if (params["id"]) {
                partId = Number(params["id"]);
                if (Number.isNaN(partId)) {
                    partId = undefined;
                }
            }

            if (partId) {
                const loadedPart: Part|undefined = await this.partService.load(partId);
                if (loadedPart) {
                    this.part = loadedPart;
                }
            }
        });
    }

    protected componentDestroy(): void {
        if (this.parameterSubscriber) {
            this.parameterSubscriber.unsubscribe();
        }
    }

    public async willDeactivate(): Promise<void> {
        await this.save();
    }

    public async canDeactivate(): Promise<boolean> {
        return true;
    }

    public async save(autosave: boolean = true): Promise<void> {
        this.saving = true;
        await this.partEditorComponent?.save();

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const successfullySavedImage: boolean = (this.partEditorComponent?.part?.image?.imageWithMeasurementPointsAsDataURL?.length ?? 0) > 20;

        if (!autosave) {
            this.snackbar.open(`${this.translateService.instant("PartDesigner.saved")} (${successfullySavedImage ? "true" : "false"})`, undefined, {
                duration: this.snackbarDuration,
                verticalPosition: this.snackbarVerticalPosition
            });
        }
        this.saving = false;
    }

    public async delete(): Promise<void> {
        if (this.part.id) {
            const result: boolean = await this.dialogService.openDeleteDialog("DeleteDialog.title", "DeleteDialog.description");

            if (result && this.part?.id) {
                if (this.part.image?.binaryId) {
                    await this.imageService.delete(this.part.image?.binaryId);
                }
                await this.partService?.delete(this.part.id);
                await this.navigationService.navigateBack();
                this.snackbar.open(this.translateService.instant("PartDesigner.deleted"), undefined, {
                    duration: this.snackbarDuration,
                    verticalPosition: this.snackbarVerticalPosition
                });
            }
        }
    }

    public async duplicate(): Promise<void> {
        if (this.part.id) {
            const duplicatedPartId: number|undefined = await this.partService.duplicate(this.part);
            if (duplicatedPartId) {
                await this.navigationService.navigateForward(`parts/${duplicatedPartId}`);
                this.snackbar.open(this.translateService.instant("PartDesigner.duplicatedInfo"), undefined, {
                    duration: this.snackbarDuration,
                    verticalPosition: this.snackbarVerticalPosition
                });
            }
        }
    }
}
