/**
 * MeasuredValue represents a value received from a measurement device. Each measured values consists of a name, value and a unit.
 *
 * Unit may be null if the measurement was faulty.
 */
export class MeasuredValue {
    public name?: string;
    public value?: string;
    public unit?: string;
    public systemValue?: boolean = false;

    public static isValidValue(measuredValue: MeasuredValue): boolean {
        return measuredValue.value != undefined && measuredValue.value != "nan";
    }
}
