export enum DeviceNames {
    unknown = "unknown",

    iq = "Rhopoint IQ",
    glossmeter = "Rhopoint Instruments",
    detailometer = "Detailometer",
    duo = "Novo-Shade Duo",
    novoGloss = "Novo-Gloss",
    flex = "IQ Flex",

    thicknessGauge = "Thickness Gauge"
}
