import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertController, ModalController } from "@ionic/angular";
import type { ComponentRef } from "@ionic/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";

import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { ErrorDialogComponent } from "../../components/error-dialog/error-dialog.component";
import { ErrorMessage } from "../../components/error-dialog/error-message";
import { RegisterFormComponent } from "../../components/register-form/register-form.component";
import { DeviceSubscriptionStatus } from "../subscription/device.subscription.status";

/**
 * Service to work with dialogs.
 */
@Injectable({
    providedIn: "root"
})
export class DialogService {
    constructor(
        private dialog: MatDialog,
        private messageService: MessageService,
        private readonly alertController: AlertController,
        private readonly modalController: ModalController,
        private readonly translateService: TranslateService
    ) {
    }

    public async openDeleteDialog(title: string, description: string, confirmPassword?: string): Promise<boolean> {
        if (!confirmPassword) {
            return this.confirm(title, description, "Generic.delete");
        } else {
            const inputPassword: string|undefined = await this.prompt(title, description);
            const passwordMatches: boolean = inputPassword?.toLowerCase() == confirmPassword?.toLowerCase() ?? false;
            if (!passwordMatches) {
                await this.alert("DeleteDialog.passwordIncorrect", "DeleteDialog.passwordIncorrectMessage");
            }
            return passwordMatches;
        }
    }

    public async confirm(title: string, description: string, okLabel?: string, cancelLabel?: string): Promise<boolean> {
        const alert: HTMLIonAlertElement = await this.alertController.create({
            header: this.translateService.instant(title) as string,
            message: this.translateService.instant(description) as string,
            keyboardClose: true,
            buttons: [
                {
                    text: (okLabel ? this.translateService.instant(okLabel) : this.translateService.instant("Generic.ok")) as string,
                    role: "confirm"
                },
                {
                    text: (cancelLabel ? this.translateService.instant(cancelLabel) : this.translateService.instant("Generic.cancel")) as string,
                    role: "cancel"
                }
            ]
        });

        await alert.present();

        const alertElement: HTMLInputElement = document.getElementById(alert.id) as HTMLInputElement;
        const keyDownHandler: (event: KeyboardEvent) => void = (event: KeyboardEvent) => {
            if (event.key === "Enter") { alert.dismiss(null, "confirm"); }
        };
        alertElement.addEventListener("keydown", keyDownHandler);

        const result: { role: string|undefined } = await alert.onWillDismiss() as unknown as { role: string|undefined };

        alertElement.removeEventListener("keydown", keyDownHandler);

        return result.role == "confirm";
    }

    public async alert(title: string, description: string, okLabel?: string): Promise<void> {
        const alert: HTMLIonAlertElement = await this.alertController.create({
            header: this.translateService.instant(title) as string,
            message: this.translateService.instant(description) as string,
            buttons: [
                {
                    text: (okLabel ? this.translateService.instant(okLabel) : this.translateService.instant("Generic.ok")) as string,
                    role: "confirm"
                }
            ]
        });

        alert.present().then();
        await alert.onWillDismiss();
    }

    public async prompt(title: string, description: string|undefined, value: string|undefined = "", okLabel?: string, cancelLabel?: string): Promise<string|undefined> {
        const inputId: string = CryptoHelper.getUUID();
        const alert: HTMLIonAlertElement = await this.alertController.create({
            header: this.translateService.instant(title) as string,
            message: description ? this.translateService.instant(description) as string : undefined,
            inputs: [
                {
                    name: "textInput",
                    type: "text",
                    placeholder: "",
                    id: inputId,
                    value: value ?? ""
                }
            ],
            buttons: [
                {
                    text: (okLabel ? this.translateService.instant(okLabel) : this.translateService.instant("Generic.ok")) as string,
                    role: "confirm"
                },
                {
                    text: (cancelLabel ? this.translateService.instant(cancelLabel) : this.translateService.instant("Generic.cancel")) as string,
                    role: "cancel"
                }
            ]
        });

        await alert.present();

        setTimeout(() => {
            const inputField: HTMLInputElement = document.getElementById(inputId) as HTMLInputElement;
            const keyDownHandler: (event: KeyboardEvent) => void = (event: KeyboardEvent) => {
                if (event.key === "Enter") {
                    alert.dismiss({ values: { textInput: inputField.value } }, "confirm");
                } else if (event.key === "Escape") {
                    alert.dismiss(null, "cancel");
                }
            };

            if (inputField) {
                inputField.focus();
                inputField.addEventListener("keydown", keyDownHandler);

                // Clean up by removing the event listener when the alert is dismissed
                alert.onDidDismiss().then(() => {
                    inputField.removeEventListener("keydown", keyDownHandler);
                });
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        300);

        const result: { data: { values?: { textInput?: string } }; role: string | undefined } = await alert.onWillDismiss() as unknown as { data: { values?: { textInput?: string } }; role: string | undefined };

        if (result.role === "confirm" && result.data && result.data.values) {
            return result.data.values.textInput;
        } else {
            return undefined;
        }
    }

    public licenseRequiredDialog(subscriptionStatus: DeviceSubscriptionStatus, serialNumber?: string, deviceCode?: string): void {
        this.dialog.open(ErrorDialogComponent, {
            data: {
                title: "Subscription.missingSubscription",
                advice: [subscriptionStatus],
                details: [],
                severity: "warn",
                cancelButton: true,
                buttons: [{
                    title: "ErrorDialog.requestLicense",
                    component: RegisterFormComponent,
                    payload: { serialNumber: serialNumber, deviceCode: deviceCode }
                }]
            } as ErrorMessage
        });
    }

    public toast(severity: "success"|"info"|"warn"|"error", text: string, title?: string, sticky?: boolean): void {
        this.messageService.add({ severity: severity, summary: title, detail: text, sticky: sticky ?? false, contentStyleClass: "allow-line-feed", styleClass: "app-safe-area-margin" });
    }

    public async openDialog<TResult, TComponent extends ComponentRef = ComponentRef>(component: TComponent, settings: { fullscreen: boolean } = { fullscreen: false }, payload: { [key: string]: any }|undefined = undefined): Promise<TResult|undefined> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: component as ComponentRef,
            componentProps: payload,
            cssClass: settings.fullscreen ? "fullscreen-modal" : ""
        });
        modal.present().then();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { data } = await modal.onWillDismiss();

        return data ? data as TResult : undefined;
    }
}
