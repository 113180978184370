import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { CloneUtil } from "../../../base/helper/clone-util";
import { Property } from "../../datamodel/property";
import { PropertyEditDialogComponent } from "../property-edit-dialog/property-edit-dialog.component";

/**
 * PropertyListComponent lists the given Properties in a table.
 */
@Component({
    selector: "app-property-list",
    templateUrl: "./property-list.component.html",
    styleUrls: ["./property-list.component.scss"]
})
export class PropertyListComponent {
    constructor(public dialog: MatDialog) {
    }

    public displayedColumns: Array<string> = ["name", "value"];
    @Input()
    public propertyItems: Array<Property> = [];
    @Input()
    public isReadonly: boolean = false;
    @Input()
    public allowNewProperties: boolean = true;
    @Input()
    public highlightFirstProperties: number = 0;

    @Output("propertiesUpdatedEvent")
    public propertiesUpdatedEvent: EventEmitter<Array<Property>> = new EventEmitter<Array<Property>>();

    @Input()
    public set properties(properties: Array<Property>) {
        this.propertyItems = [];
        for (const property of properties) {
            if (!property.deleted) {
                this.propertyItems.push(property);
            }
        }
    }

    public editProperty(property?: Property): void {
        if (this.isReadonly) {
            return;
        }
        const dialogRef: MatDialogRef<PropertyEditDialogComponent> = this.dialog.open(PropertyEditDialogComponent, {
            data: property ? CloneUtil.clone(property) : undefined
        });

        dialogRef.afterClosed().subscribe((result?: Property) => {
            for (const p of this.propertyItems) {
                if (p.nameKey == result?.oldNameKey) {
                    p.nameKey = result?.nameKey;
                    p.oldNameKey = undefined;
                    p.value = result?.value;
                    p.deleted = result?.deleted ? result.deleted : false;
                }
            }

            const updatedProperties: Array<Property> = [];
            for (const p of this.propertyItems) {
                if (p.nameKey && !p.deleted) {
                    updatedProperties.push(p);
                }
            }

            if (result?.isNew && result?.nameKey) {
                result.isNew = false;
                result.deletable = true;
                updatedProperties.push(result);
            }
            this.propertiesUpdatedEvent.emit(updatedProperties);
        });
    }
}
