<app-menubar [header]="caption" [showBackButton]="true" [hideTitleOniOS]="true">
    <button (click)="exportTemplate()" class="iconButton" color="primary" mat-stroked-button>
        <fa-icon [icon]="appIcons.fileIn"></fa-icon>
        <span>{{'JobTemplate.exportTemplate' | translate}}</span>
    </button>
</app-menubar>

<ion-content [fullscreen]="true">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">{{caption|translate}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <div class="content">
        <mat-card>
            <mat-card-content>
                <app-property-list
                    [highlightFirstProperties]="3"
                    (propertiesUpdatedEvent)="setJobProperties($event)"
                    [propertyItems]="getJobProperties()">
                </app-property-list>
            </mat-card-content>
        </mat-card>

        <h3>{{'JobDesigner.parts' | translate}}</h3>
        <div (cdkDropListDropped)="dropPart($event)" cdkDropList class="part-list">
            <div *ngFor="let part of jobTemplate.parts" class="part">
                <div [cdkDragDisabled] cdkDrag cdkDragLockAxis="y" class="partDragItem">
                    <fa-icon [icon]="appIcons.genericDragUpDown" cdkDragHandle class="dragHandle"></fa-icon>
                    <div class="partPreview">
                        <app-part-view [part]="part"></app-part-view>
                        <button (click)="removePart(part)" class="removeButton" color="warn" mat-mini-fab>
                            <fa-icon [icon]="appIcons.genericDelete"></fa-icon>
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <button
            (click)="openPartSelection()"
            class="bigButton"
            mat-stroked-button>
            {{'JobDesigner.addPart' | translate}}
        </button>

        <button
            (click)="delete()"
            *ngIf="jobTemplate.id"
            class="bigButton"
            color="warn"
            mat-flat-button>
            {{'Generic.delete' | translate}}
        </button>
    </div>
</ion-content>
