import { Injectable } from "@angular/core";

import { Job } from "../../datamodel/job";
import { JobTemplate } from "../../datamodel/job-template";
import { db } from "../../db";

/**
 * JobService stores and loads jobs.
 */
@Injectable({
    providedIn: "root"
})
export class JobService {
    public async createJob(jobTemplate: JobTemplate): Promise<number> {
        const job: Job = Job.from(jobTemplate);
        return await this.save(job);
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public async listJobs(limit: number = 101): Promise<Array<Job>> {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return db.transaction("r", db.jobs, async () => await db.jobs.orderBy("id").reverse().limit(limit).toArray());
    }

    public async save(job: Job): Promise<number> {
        return db.transaction("rw", db.jobs, async () => {
            let jobId: number;
            if (job.id) {
                await db.jobs.update(job.id, job);
                jobId = job.id;
            } else {
                jobId = await db.jobs.add(job);
            }
            return jobId;
        });
    }

    public async load(id: number): Promise<Job|undefined> {
        const job: Job|undefined = await db.transaction("r", db.jobs, async () => await db.jobs.where({ "id": id }).first());

        // Upgrade from Version 1.0.0 to 1.3.0
        for (const measuredPoint of job?.measuredPoints ?? []) {
            for (const measurement of measuredPoint.measurements ?? []) {
                measurement.charts = measurement.charts ?? [];

                for (const value of measurement.values) {
                    if (value.name?.includes("thickness.name")) {
                        value.name = value.name.replace("thickness.name", "thickness");
                    }
                }
            }
        }

        return job;
    }

    public async delete(id: number): Promise<void> {
        await db.transaction("rw", db.jobs,
            async () => await db.jobs.where({ "id": id }).delete());
    }
}
