<table *ngIf="highlightLastMeasurement && lastMeasurement" class="lastMeasuredTable">
    <tr *ngIf="lastMeasurement.localId > 0">
        <td>{{'Measurement.localId'|translate}}</td>
        <td>{{uiHelper.numberToLetters(lastMeasurement.localId)}}</td>
        <td>&nbsp;</td>
    </tr>
    <tr *ngFor="let measuredValue of lastMeasurement.values" [hidden]="measuredValue.systemValue">
        <td>
            {{("" + measuredValue.name)|translate}}
        </td>
        <td>
            {{uiHelper.formatValue(measuredValue)|translate}}
        </td>
        <td>
            {{uiHelper.formatValueUnit(measuredValue)|translate}}
        </td>
    </tr>
</table>

<div class="measurementsList">
    <table #measurementMatTable *ngIf="displayedRows.length > 0" [dataSource]="displayedRows" mat-table>
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <th *matHeaderCellDef [id]="column" mat-header-cell [class.lineLeft]="column == specialColumns.comments || column == specialColumns.name" [class.lineRight]="column == specialColumns.name">
                <span *ngIf="column == specialColumns.localId" class="cellHeaderLocalId">{{column|translate}}<br />&nbsp;</span>
                <span *ngIf="column == specialColumns.name" class="cellHeaderName">{{column|translate}}<br />&nbsp;</span>
                <span *ngIf="column == specialColumns.charts" class="cellHeaderCharts">{{column|translate}}<br />&nbsp;</span>
                <span *ngIf="column != specialColumns.localId && column != specialColumns.name && column != specialColumns.comments && column != specialColumns.charts" class="cellHeader">{{measurementTableComponent.getTitleForColumn(column)}}<br />&nbsp;{{measurementTableComponent.getUnitForColumn(column, measurements, true, true)}}</span>
                <span *ngIf="column == specialColumns.comments" class="cellHeaderComment">{{column|translate}}<br />&nbsp;</span>
            </th>

            <td *matCellDef="let row" mat-cell [class.separator]="row.separator" [class.lineLeft]="!row.statistic && (column == specialColumns.comments || column == specialColumns.name)" [class.lineRight]="!row.statistic && (column == specialColumns.comments || column == specialColumns.name)">
                <ng-container *ngIf="row.measurement">
                    <span (click)="editName(row.measurement)" *ngIf="column == specialColumns.localId" class="cellContentLocalId">
                        <span *ngIf="row.measurement.localId > 0">{{uiHelper.numberToLetters(row.measurement.localId)}}</span>
                    </span>
                    <span (click)="openChart(row.measurement)" *ngIf="column == specialColumns.charts && row.measurement.charts.length > 0" class="cellContentCharts">
                        <fa-icon [icon]="appIcons.chart"></fa-icon>
                    </span>
                    <span (click)="editName(row.measurement)" *ngIf="column == specialColumns.name" class="cellContentName">
                        {{(row.measurement.name ? row.measurement.name : '&nbsp;' )}}
                    </span>

                    <span *ngIf="column != specialColumns.localId && column != specialColumns.name && column != specialColumns.actions && column != specialColumns.comments && column != specialColumns.charts" class="cellContent">
                        {{measurementTableComponent.getValueForColumn(column, row.measurement) | translate}}
                    </span>
                    <span (click)="editComment(row.measurement)" *ngIf="column == specialColumns.comments" class="cellContentComment">
                        {{measurementTableComponent.getValueForColumn(column, row.measurement)}}
                    </span>

                    <span (click)="deleteMeasurement(row.measurement)" *ngIf="column == specialColumns.actions">
                        <fa-icon [icon]="appIcons.trash" class="cellContent clickable"></fa-icon>
                    </span>
                </ng-container>
                <ng-container *ngIf="row.statistic">
                    <span *ngIf="column == specialColumns.localId" class="cellContentLocalId">
                        {{row.statistic[specialColumns.statisticType]|translate}}
                    </span>
                    <span class="cellContent">{{getStatisticsValue(row.statistic, column) | translate}}</span>
                </ng-container>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" class="tableHeader" mat-header-row></tr>
        <tr *matRowDef="let p; columns: displayedColumns;" mat-row></tr>
    </table>
</div>

<div *ngIf="measurements.length == 0">
    <mat-card>
        <mat-card-content>{{'QuickMode.noMeasurements' | translate}}</mat-card-content>
    </mat-card>
</div>

