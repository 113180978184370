/* eslint-disable @typescript-eslint/no-magic-numbers */
import Dexie, { Table } from "dexie";

import { SubscriptionDto } from "../generated/api";
import { Job } from "./datamodel/job";
import { JobTemplate } from "./datamodel/job-template";
import { Part } from "./datamodel/part";
import { MeasurementV1 } from "./pages/job-designer/sharing/v1/measurement.v1";
import { ImageStorageItem } from "./services/image/image-storage-item";
import { AppSettings } from "./services/settings/app-settings";

/**
 * AppDB configures the indexeddb for usage.
 */
export class AppDB extends Dexie {
    constructor() {
        super("detailing-db", {
            autoOpen: true
        });
        this.version(1).stores({
            jobTemplates: "++id",
            jobs: "++id",
            parts: "++id",
            images: "++id"
        });
        this.version(2).stores({
            personalization: "++id"
        });
        this.version(3).stores({
            deviceSubscriptions: "++id"
        });
        this.version(4).stores({
            jobTemplates: "++id, correlationId",
            jobs: "++id, correlationId",
            parts: "++id, correlationId"
        });
        this.version(5).stores({
            quickMeasurements: "id, localId"
        });
    }

    public jobTemplates!: Table<JobTemplate, number>;
    public jobs!: Table<Job, number>;
    public parts!: Table<Part, number>;
    public images!: Table<ImageStorageItem, number>;
    public personalization!: Table<AppSettings, number>;
    public deviceSubscriptions!: Table<SubscriptionDto, number>;
    public quickMeasurements!: Table<MeasurementV1, string>;
}

export const db: AppDB = new AppDB();
